import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ["cheapest", "upsground", "uspspriority", "twoday"];

  connect() {
    console.log(this.cheapestTarget);
    this.cheapestTarget.addEventListener("click", () =>
      this.handleShippingClick("data-cheapest")
    );
    this.upsgroundTarget.addEventListener("click", () =>
      this.handleShippingClick("data-ups_ground")
    );
    this.uspspriorityTarget.addEventListener("click", () =>
      this.handleShippingClick("data-usps_priority")
    );
    this.twodayTarget.addEventListener("click", () =>
      this.handleShippingClick("data-two_day_shipping")
    );
  }

  handleShippingClick = async (dataAttribute) => {
    const selectElements = document.querySelectorAll(
      ".shipping-rate-estimates"
    );

    for (const selectElement of selectElements) {
      const shippingOption = selectElement.querySelector(
        `option[${dataAttribute}="true"]`
      );

      if (shippingOption) {
        selectElement.value = shippingOption.value;
      } else {
        console.log(`No option found for ${dataAttribute}.`);
      }

      await this.updateShippingRate(selectElement);
    }
  };

  updateShippingRate = async (selectElement) => {
    await jQuery(selectElement).trigger("change.select2");
    const form = document.getElementById(
      `form_${selectElement.id.split("_")[1]}`
    );
    form.action = form.action.replace("/print_label", "/set_rates");

    const formData = new FormData(form);
    formData.set(`rate_${selectElement.id.split("_")[1]}`, selectElement.value);

    try {
      const response = await post(form.action, {
        responseKind: "turbo-stream",
        body: formData,
      });

      if (response.ok) {
        console.log("Success:", response.statusText);
        form.action = form.action.replace("/set_rates", "/print_label");
      } else {
        console.error("Failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
}
