import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="fulfillment--fetch-data"
export default class extends Controller {
  static targets = ["packages", "addresses", "recipients", "teams", "exceptTeams", "items", "multipleItems", "groupOrders", "exceptGroupOrders", "carriers"]

  static values = {
    params: { type: Object }
  }

  connect() {
    this.fetch_packages();
    this.fetch_addresses();
    this.fetch_filter_details();
    this.fetch_carriers();
  }

  async fetch_filter_details(){
    if (!this.hasRecipientsTarget) {
      return;
    }

    try {
      const searchParams = this.paramsValue.q
      const orderIds = $('.orders_id').map(function () {
        return $(this).val(); // Assuming these are input elements and you want their values
      }).get();
      const response = await get(`/paige/fetch_data/details?order_ids=${orderIds}&state=${this.paramsValue.action}`, {
        responseKind: "application/json",
        contentType: "application/json"
      });
      if (response.ok) {
        const data = await response.json;
        await this.setTargetData(this.recipientsTarget, data.recipients, 'Select Recipient', searchParams?.recipient_id_eq)
        await this.setTargetData(this.teamsTarget, data.teams, 'Select Team', searchParams?.team_id_eq)
        await this.setTargetData(this.exceptTeamsTarget, data.teams, 'Select Team', searchParams?.team_id_not_eq)
        await this.setTargetData(this.itemsTarget, data.products, 'Select Item', searchParams?.product_variants_id_eq)
        await this.setTargetData(this.multipleItemsTarget, data.products, 'Multiple Items or Bundles', searchParams?.product_variants_id_in)
        await this.setTargetData(this.groupOrdersTarget, data.group_orders, 'Select Group Order', searchParams?.paige_group_order_id_eq)
        await this.setTargetData(this.exceptGroupOrdersTarget, data.group_orders, 'Select Group Order', searchParams?.paige_group_order_id_not_eq)

      } else {
        console.error('Failed to fetch data from:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data from:', error);
    }
  }

  async fetch_packages() {
    if (!this.hasPackagesTarget) {
      return;
    }
    await this.fetchData('/paige/fetch_data/shipping_packages', this.packagesTarget, 'Select a mailing box');
  }

  async fetch_addresses() {
    if (!this.hasAddressesTarget) {
      return;
    }
    await this.fetchData('/paige/fetch_data/address_books', this.addressesTarget, 'Select an address');
  }

  async fetchData(endpointUrl, target, placeholderText) {
    try {
      const response = await get(endpointUrl, {
        responseKind: "application/json",
        contentType: "application/json"
      });
      if (response.ok) {
        const data = await response.json;
        await this.setTargetData(target, data.data, placeholderText)
      } else {
        console.error('Failed to fetch data from:', endpointUrl, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data from:', endpointUrl, error);
    }
  }

  async setTargetData(target, data, placeholderText, selectedValue = null) {
    $(target).select2({
      data: data,
      allowClear: true,
      placeholder: placeholderText
    });

    if (selectedValue) {
      $(target).val(selectedValue).trigger('change');
    }
  }

  async fetch_carriers(){
    if (!this.hasCarriersTarget) {
      return;
    }

    await this.fetchData('/paige/fetch_data/carriers', this.carriersTarget, 'Select a carrier');
  }
}
