import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import select2 from "./js/select";
import "bootstrap/dist/js/bootstrap.min";
import "@fortawesome/fontawesome-free/js/all";
import jQuery from "jquery";
import "./controllers";
import toastr from "toastr";
import "gasparesganga-jquery-loading-overlay/src/loadingoverlay";
import "bootstrap/dist/js/bootstrap";
import { Uppy } from "@uppy/core";
import "chartkick/chart.js";

const algoliasearch = require("algoliasearch");

import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";

const client = algoliasearch(
  window.env.ALGOLIA_APPLICATION_ID,
  window.env.ALGOLIA_SEARCH_ONLY_API_KEY
);

const pack = {
  algoliaClient: client,
  autocomplete: autocomplete,
  getAlgoliaResults: getAlgoliaResults,
};

window.Rails = Rails;
window.jQuery = window.$ = jQuery;
window.toastr = toastr;
window.autocomplete = autocomplete;
window.Pack = pack;
window.Uppy = Uppy;

select2($);

require("@rails/activestorage").start();

$(document).on("turbo:load", function () {
  $(".select2").select2();
  $('[data-toggle="tooltip"]').tooltip();
  $(".dynamic_sorting").sortable({
    connectWith: ".dynamic_sorting",
    dragoverBubble: false,
    cursor:  "move",
    update: function (e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PUT",
        data: $(this).sortable("serialize"),
      });
    },
  });
});

$(document).on("turbo:before-cache", function () {

  $(".select2-hidden-accessible").select2("destroy");
  $('[data-toggle="tooltip"]').tooltip();
});

require("./js/bootstrap");
require("./js/uppy");

$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

// The following are some turbo events that are not needed for the current project and can be experimented for if needed
document.addEventListener("turbo:load", () => {
});

document.addEventListener("turbo:stream-render", (event) => {
  console.log("after stream render:", event);
});

document.addEventListener("turbo:morph-element", (event) => {
  console.log("Before frame render:", event);
});

document.addEventListener("turbo:frame-render", (event) => {
  console.log("turbo:frame-render:", event);
});

document.addEventListener("turbo:frame-render", (event) => {
  console.log("Frame render:", event);
});

document.addEventListener("turbo:frame-load", (event) => {
  console.log("Frame load:", event);
});

document.addEventListener("turbo:submit-end", (event) => {
  $('[data-toggle="tooltip"]').tooltip();
  console.log("Turbo render:", event);
  $("select").select2();
});

document.addEventListener("turbo:after-render", (event) => {
  console.log("Turbo stream replacement completed.");
});

document.addEventListener("turbo:before-stream-render", function (event) {
  console.log("About to replace some-element-id");
  // Run select2 after 5 seconds
  setTimeout(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $("select").select2();
  }, 500);
});
