import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "productVariant",
    "qty",
    "unitPrice",
    "total",
    "retailPrice",
    "newQty",
    "newUnitPrice",
    "newTotal",
  ];

  connect() {
    $(this.productVariantTarget).on("select2:select", (e) => {
      const element = e.params.data.element;
      const cost = $(element).data("cost");
      this.changeUnitPrice(this.unitPriceTarget, cost);
    });

    $(this.qtyTarget, this.unitPriceTarget).on("change", (e) => {
      e.preventDefault();
      this.calculateAmount(
        this.totalTarget,
        this.qtyTarget,
        this.unitPriceTarget
      );
    });

    $(this.retailPriceTarget).on("change", (e) => {
      e.preventDefault();
      this.changeUnitPrice(
        this.newUnitPriceTarget,
        this.retailPriceTarget.value
      );
    });

    $(this.newQtyTarget, this.newUnitPriceTarget).on("change", (e) => {
      e.preventDefault();
      this.calculateAmount(
        this.newTotalTarget,
        this.newQtyTarget,
        this.newUnitPriceTarget
      );
    });
  }

  changeUnitPrice(target, cost) {
    if (cost !== undefined) {
      $(target).val(cost);
    }
  }

  calculateAmount(totalPrice, qty, unitPrice) {
    totalPrice.value = qty.value * unitPrice.value;
  }
}
