import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mergeLink"];

  updateLink() {
    const product_id = document.getElementById("product_id").value;
    let product_variant_ids = this.selectedVariantIds();

    this.mergeLinkTarget.setAttribute(
      "href",
      `/admin/product_variants/edit_merge_multiple?product_id=${product_id}&product_variant_ids=${product_variant_ids}`
    );
  }

  selectedVariantIds() {
    return Array.from(
      document.querySelectorAll(
        'input[type="checkbox"][name="product_variants"]:checked'
      )
    )
      .map((input) => input.value)
      .join(",");
  }
}
