import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fulfillment--mark-as-shipped"
export default class extends Controller {
  static targets = ["modal"];

  validateAndOpen(event) {
    const orderIds = Array.from(
      document.querySelectorAll(".orders_id:checked")
    ).map((input) => input.value);
    const ecommerceOrdersPresent = Array.from(
      document.querySelectorAll(".orders_id:checked")
    )
      .map(
        (input) =>
          input.closest("tr").querySelector("input#e_commerce_cart_id").value
      )
      .filter((v) => v != "").length;
    const shippingLabelPresent =
      Array.from(document.querySelectorAll(".orders_id:checked"))
        .map((input) =>
          input.closest("tr").querySelector(".shipping_label_present")
        )
        .filter((td) => td != null).length > 0;

    console.log(orderIds);
    if (orderIds.length === 0) {
      alert("No orders selected.");
      event.preventDefault();
    } else if (ecommerceOrdersPresent > 0 && !shippingLabelPresent) {
      $("#shippingCostModal").modal("show");
      event.preventDefault();
    } else {
      window.location.href =
        "/paige/orders/fulfillment/mark_as_shipped?order_ids=" +
        orderIds.join(",");
    }
  }
}
