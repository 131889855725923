import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="group-order--cards"

export default class extends Controller {
  static targets = ["cardItem", "select", "newCardItem", "cardTable"];

  connect() {
    this.initializeSelect2();
  }

  initializeSelect2() {
    this.selectTargets.forEach((select) => {
      $(select).select2({ theme: "bootstrap" });
    });
  }

  addCardItem(event) {

    event.preventDefault();
    console.log("Adding card item");

    // Destroy select2 before cloning
    this.selectTargets.forEach((select) => {
      $(select).select2('destroy');
    });

    // Clone the last card item
    const newCardItem = this.cardItemTargets[this.cardItemTargets.length - 1].cloneNode(true);

    // Increment the id for the new card item
    const newSelect = newCardItem.querySelector("select.select2");
    const noOfDivs = this.cardItemTargets.length;
    newSelect.id = `card-item${noOfDivs}`;

    // Append the new card item to the table
    this.cardTableTarget.appendChild(newCardItem);

    // Reinitialize select2 on all select elements
    this.initializeSelect2();
  }

  removeCardItem(event) {

    event.preventDefault();
    console.log("Removing card item");
    // Only remove if there are more than one card items left
    if (this.cardItemTargets.length > 1) {
      event.target.closest("tr.card-item").remove();
    }
  }
}
