import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkValidation", "validateForm"];
  connect() {
    this.validateFormTarget.addEventListener("submit", (event) => {
      this.checkValidationTarget.value = "Validating...";
      this.checkValidationTarget.disabled = true;
      setTimeout(() => {
        this.checkValidationTarget.value = "Validate Address";
        this.checkValidationTarget.disabled = false;
      }, 1800);
    });
  }
}
