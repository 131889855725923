import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

// Connects to data-controller="order--set-shipping"
export default class extends Controller {
  static targets = [
    "fillValues",
    "orderForm",
    "orderRecipientForm",
    "recipientDimensions",
    "recipientWeight",
    "flatUsps",
    "oneRate",
    "newFedex",
    "shipDate",
    "weight",
    "weightUnit",
    "dimensions",
    "excludedCarrier",
    "fromAddress",
    "recipientRates",
  ];
  static values = { data: Object };
  connect() {}
}
