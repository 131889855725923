import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="product--tags"

export default class extends Controller {
  static targets = ["addTagsIcon", "addTagsDropdown", "selectTag"];

  connect() {
    this.csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  }

  addTag(event) {
    const productId = event.currentTarget.getAttribute("data-product-id");
    const tagId =
      event.currentTarget.previousElementSibling.selectedOptions[0].value;

    if (tagId !== "") {
      this.addProductTags(productId, tagId);
    } else {
      $("." + productId + ".add-tags-dropdown").hide();
      $("." + productId + ".add-tags-icon").show();
    }
  }

  removeTag(event) {
    const productId = event.currentTarget.getAttribute("data-product-id");
    const tagId = event.currentTarget.getAttribute("data-tag-id");

    if (tagId !== "") {
      this.removeProductTag(productId, tagId);
    }
  }

  showAddTagsDropdown(event) {
    const productId = event.target.dataset.productId;
    $("." + productId + ".add-tags-icon").hide();
    $("." + productId + ".add-tags-dropdown").show();
  }

  async addProductTags(productId, tagId) {
    const url = `/admin/products/${productId}/add_tags`;

    const response = await post(url, {
      body: JSON.stringify({ product: { tag_id: tagId } }),
      contentType: "application/json",
      responseKind: "json",
      headers: { "X-CSRF-Token": this.csrfToken },
    });

    if (response.ok) {
      const result = await response.json;
      document.querySelector(
        `#product-${result.product_id} .tag-buttons`
      ).innerHTML += `
        <button type='button' class='label label-default' data-product-id='${productId}' data-tag-id='${result.tag_id}' data-action="click->product--tags#removeTag">
          <i class='fas fa-times'></i>
          <span class='label-tag'> ${result.tag_name}</span>
        </button>`;
      $("." + productId + ".add-tags-icon").hide();
      $("." + productId + ".add-tags-dropdown").hide();
    } else {
      console.error("Error adding tag:", response);
    }
  }

  async removeProductTag(productId, tagId) {
    const url = `/admin/products/${productId}/remove_tag`;

    const response = await post(url, {
      body: JSON.stringify({ product: { tag_id: tagId } }),
      contentType: "application/json",
      responseKind: "json",
      headers: { "X-CSRF-Token": this.csrfToken },
    });

    if (response.ok) {
      const result = await response.json;
      document
        .querySelector(`#product-${productId} .tag-${result.tag_id}`)
        .remove();
      $("." + productId + ".add-tags-icon").show();
    } else {
      console.error("Error removing tag:", response);
    }
  }
}
