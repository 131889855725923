import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

// Connects to data-controller="fulfillment--print-label"
export default class extends Controller {
  static targets = ["orderform", "generateLabel"];

  connect() {
    this.generateLabelTarget.addEventListener("click", async () => {
      this.generateLabelTarget.disabled = true;
      this.generateLabelTarget.textContent = "Generating...";
      Promise.all(
        this.orderformTargets.map((form) =>
          post(form.action, { responseKind: "turbo-stream" })
        )
      )
        .then(() => {
          this.generateLabelTarget.disabled = false;
          this.generateLabelTarget.textContent = "Generate Label";
        })
        .catch((error) => {
          console.error("Error submitting forms:", error);
          this.generateLabelTarget.disabled = false;
          this.generateLabelTarget.textContent = "Generate Label";
        });
    });
  }
}
