import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

// Connects to data-controller="fulfillment--get-rates"
export default class extends Controller {
  static targets = ["rates", "orderform", "selectTag"];
  static values = { data: Object, url: String };

  connect() {
    if (this.dataValue.order) {
      $(this.ratesTarget).LoadingOverlay("show", {
        background: "#F6F2EE",
        maxSize: 5,
      });

      const url = this.hasUrlValue
        ? this.urlValue
        : "/admin/shipping_labels/get_shipping_rates";
      this.getRates(url, this.hasUrlValue ? "post" : "get");
    }
  }

  async getRates(url, method) {
    try {
      let queryString = new URLSearchParams(this.dataValue).toString();
      const response = await (method === "post" ? post : get)(
        `${url}?${queryString}`,
        {
          responseKind: "turbo-stream",
          headers: {
            Accept: "text/vnd.turbo-stream.html",
          },
        }
      );

      if (response.ok) {
        $(this.ratesTarget).LoadingOverlay("hide");
      }
    } catch (error) {
      console.error("Error fetching data from:", error);
    }
  }
}
